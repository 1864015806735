<template>
  <div class="newlist">
    <HeadMobile/>
    <div class="newlist-main">
      <div class="newlist-main-weizhi">{{$store.state.staticName.position}}：
        <span @click="goHome">{{$store.state.staticName.home}}</span>
        >
<!--        企业新闻-->
              {{title}}
      </div>
      <div class="newlist-main-titles">
              <div class="newlist-main-title">{{title}}</div>
<!--              <div class="newlist-main-synopsis">{{detilData.synopsis}}</div>-->
      </div>
      <div class="newlist-main-list">
        <div class="new-item" v-for="(item,i) in listData.value" :key="'new'+i" @click="toDetil(item)">
          <div class="new-img" v-if="item.abbreviation">
            <img class="new-img" :src="item.abbreviation[0]"/>
          </div>
          <div class="new-info">
            <div class="new-box">
              <div class="new-title">
                {{item.title}}
                <!--                  攀枝花市午跃科技有限公司于2017年08月01日成立法定代表人覃美玲，公司经营范围包括假肢人...-->
              </div>
              <div class="new-new">
                {{item.synopsis}}
                <!--                  攀枝花市午跃科技有限公司于2017年08月01日成立。法定代表人覃美玲，公司经营范围包括：假肢、人工器官及植（介）入器械制造；无障碍设施生产、组装、维护；设计、攀枝花市午跃科技有限公司于2017年08月01...-->
              </div>
            </div>
            <div class="new-date">
              {{item.time}}
              <!--                  2020-12-07-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new-pagination1">
      <new-pagination
          @current-change="handleCurrentChange"
          :page-size=page1.pageSize
          layout="prev, pager, next"
          :total=total
      ></new-pagination>
    </div>
    <FootMobile/>
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import FootMobile from "@/components/mobile/FootMobile";
import { mapState } from 'vuex';
export default {
  name: "NewListMobile",
  components: {
    HeadMobile,
    FootMobile
  },
  data(){
    return{
      total:0,
      listData:[],
      title:'',
      page1: {
        type: 'CHINESE',
        newType:'',
        pageSize: 7,
        currentPage: 1
      }
    }
  },
  created() {
    this.page1.newType = this.$route.params.newType;
    this.getDataList()
  },
  watch:{
    "$store.state.languageType": {
      handler(newVal) {
        this.getDataList()
      },
      // deep: true // 深度监听
    }
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    })
  },
  methods: {
    toDetil(data) {
      this.$router.push({
        path: `/newdetailsMobile/${data.type}/${data.id}`
      })
    },
    goHome() {
      this.$router.push({path: "/HomeMobile"})
    },
    handleCurrentChange(val) {
      this.page1.currentPage=val;
      this.getDataList()
    },
    getDataList(){
     this.page1.type = this.$store.state.languageType
      this.$axios({
        url: '/queryNewsByList',
        method: 'GET',
        params: this.page1,
      }).then(res => {
        let value = res.data.value.value;
        this.title = res.data.value.title;
        this.total = res.data.value.value.total;
        this.listData=value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.new-item{
  cursor: pointer;
}
.newlist {
  background-color: #fff;

  .newlist-main {
    width: 375px;
    margin: 0 auto;
    //background-color: #f2f2f2;

    .newlist-main-weizhi {
      font-size: 10px;
      font-weight: 400;
      text-align: left;
      color: #333333;

      span:hover {
        color: #1850AF;
        cursor: pointer;
      }
    }

    .newlist-main-titles {
      width: 100%;
      margin-top: 17px;

      .newlist-main-title {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        color: #333333;
      }

      .newlist-main-synopsis {
        margin-top: 44px;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        color: #4d4d4d;
      }
    }
    .newlist-main-list{
      margin-top: 14px;
      box-sizing:border-box;
      -moz-box-sizing:border-box; /* Firefox */
      -webkit-box-sizing:border-box; /* Safari */
      padding: {
        top: 1px;
        bottom: 12px;
        left: 11px;
        right: 11px;
      };
      background-color: #f2f2f2;
      .new-item {
        //margin-top: 29px;
        //margin-bottom: 29px;
        border-bottom: 1px dashed #707070;
        padding-bottom: 12px;
        display: flex;
        margin-top: 21px;
        .new-img {
          width: 109px;
          height: 73px;
          background: rgba(0, 0, 0, 1);

        }

        .new-info {
          margin-left: 30px;
          width: 1263px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
         .new-box{
           .new-title {
             //height: 20px;
             font-size: 14px;
             font-weight: 500;
             text-align: left;
             color: #4d4d4d;
             display: -webkit-box; /**对象作为伸缩盒子模型展示**/
             -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
             -webkit-line-clamp: 1; /**显示的行数**/
             overflow: hidden; /**隐藏超出的内容**/
           }

           .new-new {
             margin-top: 6px;
             height: 35px;
             font-size: 11px;
             font-weight: 400;
             text-align: left;
             color: #808080;
             display: -webkit-box; /**对象作为伸缩盒子模型展示**/
             -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
             -webkit-line-clamp: 2; /**显示的行数**/
             overflow: hidden; /**隐藏超出的内容**/
           }
         }

          .new-date {
            //height: 18px;
            width: 100%;
            //float: right;
            font-size: 10px;
            font-weight: 400;
            text-align: right;
            color: #333333;
          }
        }
      }
      .new-item:last-child{
        margin-bottom: 0;
      }
    }

  }
  .new-pagination1{
    margin: 20px 0 18px 0 ;
  }
}
</style>